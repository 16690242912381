import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useState, useMemo } from "react";
import CustomList from "../../custom/CustomList";
import CustomTag from "../../custom/CustomTag";

const CustomerList = () => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  React.useEffect(() => {
    setIsLoaded(false);
    axios
      .get(process.env.REACT_APP_BACK_URL + "/user", {
        withCredentials: true,
      })
      .then((response) => {
        setData(response.data.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        icon: "id",
      },
      {
        Header: "Nom",
        accessor: "name",
        icon: "nom",
      },
      {
        Header: "Email",
        accessor: "email",
        icon: "nom",
      },
      {
        Header: "Rôle",
        accessor: "role",
        icon: "role",
        Cell: ({ cell: { value } }) => <CustomTag value={value} />,
      },
    ],
    []
  );
  
  return isLoaded ? (
    <article className="c-section-content">
      <div className="list-header">
        <div className="header-title customers">
          <h2>Clients</h2>
        </div>
      </div>
      <div className="transactions-container clients page-content">
        {data.length > 0 ? (
          <CustomList
            columns={columns}
            entity={data.filter((user) => user.role !== "admin")}
            pageSizeOptions={[20, 50, 100, 200]}
            isFetchable={false}
            isSelectable={false}
          />
        ) : null}
      </div>
    </article>
  ) : (
    <div className="loader-container">
      <CircularProgress size={30} />
    </div>
  );
};

export default CustomerList;
