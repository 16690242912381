import React from "react";
import jsPDF from "jspdf";
import dayjs from "dayjs";
import IconeDownload from "../../assets/icons/download-full--black.svg";

const CustomExportPDF = ({ dateRanges, property }) => {
    const exportPDF = async () => {
        const pdf = new jsPDF();
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        let yPosition = 10;
    
        pdf.setFont('Helvetica', 'bold');
        pdf.setTextColor(40, 40, 40);
        pdf.setFontSize(32);
        const titreRapport = "Rapport de statistiques".toUpperCase();
        const xOffset = pageWidth / 2;
        const yOffset = pageHeight / 2;
        pdf.text(titreRapport, xOffset, yOffset - 20, { align: 'center' });
    
        const selectedDateRange = dateRanges[0];
        const startDate = dayjs(selectedDateRange.start_date).format('D MMMM YYYY')
        const endDate = dayjs(selectedDateRange.end_date).format('D MMMM YYYY')
        const periodeText = `Période du ${startDate} au ${endDate}`.toUpperCase();
        pdf.setFontSize(12);
        pdf.text(periodeText, xOffset, yOffset, { align: 'center' });
        
        pdf.addPage();
      
        const headers = document.querySelectorAll('.header-title');
        const h2Style = {
          color: '#3b61e1',
          padding: 5,
        };
        
        headers.forEach(header => {
          const h2 = header.querySelector('h2');
          if (h2) {
            pdf.setFont('Helvetica', 'bold');
            pdf.setFontSize(16);
            const textHeight = pdf.internal.getFontSize();
            const xPosition = 10;
            pdf.setTextColor(59, 97, 225);
            const textY = yPosition + h2Style.padding;
            pdf.text(h2.textContent.trim(), xPosition, textY);
            yPosition += textHeight + 1;
            pdf.setTextColor(40, 40, 40);
          }
              
          let contentElement = header.nextElementSibling;
          while (contentElement && !contentElement.matches('.header-title')) {
            const textsToCombine = [];
            const spans = contentElement.querySelectorAll('span.c-card_title');
            const ps = contentElement.querySelectorAll('p.c-card_data');
          
            for (let index = 0; index < spans.length; index++) {
              const span = spans[index];
              const p = ps[index];
              const spanText = span.textContent.trim();
              const pText = p ? p.textContent.trim() : '';
              textsToCombine.push(spanText + ' : ' + pText);
            }
          
            function formatNumber(text) {
              return text.replace(/\s+/g, ' ');
            }
    
            function replaceInfinitySymbol(text) {
              return text.replace(/∞/g, '\u221E');
            }
            
            for (let index = 0; index < textsToCombine.length; index++) {
              let text = textsToCombine[index];
              if (text) {
                text = formatNumber(text);
                text = replaceInfinitySymbol(text);
                
                pdf.setFont('Helvetica', 'normal');
                pdf.setFontSize(12);
                if (yPosition >= pageHeight - 20) {
                  pdf.addPage();
                  yPosition = 20;
                }
                pdf.text(text, 10, yPosition);
                yPosition += 7;
              }
            }        
          
            contentElement = contentElement.nextElementSibling;
          }
      
          yPosition += 2.5;
        });
      
        pdf.setFontSize(10);
        pdf.text("© 2024 Altelis. Tous droits réservés.", xOffset, pageHeight - 10, { align: 'center' });
        const fileName = `Rapport_${property.name}_${startDate}_${endDate}.pdf`;
        pdf.save(fileName);
      };
      return (
        <button type="submit" id="button-export" onClick={exportPDF} title="Exporter les données en PDF">
          <img src={IconeDownload} alt="Icone Download"></img>
        </button>
      );
    };
    
export default CustomExportPDF;