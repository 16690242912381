import axios from "axios";

const DOMAIN_API = '/api/v1';

// Fonction générique pour effectuer une requête HTTP GET
function fetch_api_json(method, suffix_url) {
  return fetch(process.env.REACT_APP_BACK_URL + suffix_url, {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
      .then((response) => {
        return response.json();
      })
      .catch((error) => console.error(error));
}

// Fonction pour se déconnecter
export function logout() {
  return axios.post(process.env.REACT_APP_BACK_URL + 'accounts/log_out', null, {
    withCredentials: true
  }).then(response => {
    return response;
  }).catch(err => {
    return err;
  });
}

// Fonction pour obtenir l'utilisateur actuel
export function current_user() {
  return axios.get(process.env.REACT_APP_BACK_URL + '/user/me', {
    withCredentials: true
  }).then(response => {
    return response;
  }).catch(err => {
    return err;
  });
}

// Fonction pour obtenir les propriétés accessibles
export function accessible_properties() {
  return fetch_api_json('GET', '/accessible_properties');
}

// Fonction pour obtenir la liste des propriétés (admin)
export function property_list() {
  return axios.get(process.env.REACT_APP_BACK_URL + '/property', {
    withCredentials: true
  }).then(response => {
    return response;
  }).catch(err => {
    return err;
  });
}

// Fonction pour obtenir une propriété par ID
export function property_by_id(id) {
  return fetch_api_json('GET', `/property/${id}`);
}

// Fonctions liées aux rapports Google Analytics (GA4 et Universal)

// Obtenir les comptes GA4
export function ga_ga4_accounts() {
  return fetch_api_json('GET', '/ga/ga4/accounts');
}

// Obtenir les comptes Universal Analytics
export function ga_universal_accounts() {
  return fetch_api_json('GET', '/ga/universal/accounts');
}

// Obtenir les comptes GA complets
export function ga_full_accounts() {
  return fetch_api_json('GET', '/ga/full/accounts');
}

// Obtenir un compte GA complet par ID, type GA et ID de propriété
export function ga_full_account(account_id, ga_type, property_id) {
  const encoded = encodeURIComponent(property_id);
  return fetch_api_json('GET', `/ga/full/account/${account_id}/${ga_type}/${encoded}`);
}

// Obtenir un rapport d'analyse pour une propriété donnée
export function analytics_report(property_id, start_date, end_date) {
  const qs = `start_date=${start_date}&end_date=${end_date}`;
  return fetch_api_json('GET', `/property/${property_id}/ga/report?${qs}`);
}

// Obtenir un rapport GA4 pour une propriété donnée
export function ga_ga4_report(property_id, start_date, end_date) {
  return analytics_report(property_id, start_date, end_date);
}

// Fonctions administratives pour les rapports GA4
export function ga_ga4_reports() {
  return fetch_api_json('GET', '/ga/ga4/reports');
}

// Obtenir un rapport GA4 direct pour un compte, une propriété et une période données
export function ga_ga4_report_direct(account_id, property_id, start_date, end_date) {
  const encoded = encodeURIComponent(property_id);
  const qs = `start_date=${start_date}&end_date=${end_date}`;
  return fetch_api_json('GET', `/ga/ga4/report/${account_id}/${encoded}?${qs}`);
}

// Obtenir un rapport Universal Analytics pour une propriété donnée
export function ga_universal_report(property_id, start_date, end_date) {
  return analytics_report(property_id, start_date, end_date);
}

// Obtenir tous les rapports pour une propriété donnée
export function all_reports(property_id, start_date, end_date) {
  const url = process.env.REACT_APP_BACK_URL + 'property/' + property_id + '/all/report/?start_date=' + start_date + '&end_date=' + end_date;
  return axios.get(url, {
    withCredentials: true
  }).then(response => {
    return response.data;
  }).catch(err => {
    console.log(err);
    return err;
  });
}

// Obtenir des rapports macro pour une période donnée
export function macro_reports(start_date, end_date) {
  const url = process.env.REACT_APP_BACK_URL + '/properties/all/report/?start_date=' + start_date + '&end_date=' + end_date;
  return axios.get(url, {
    withCredentials: true,
  }).then(response => {
    return response.data;
  }).catch(err => {
    console.log(err);
    return err;
  });
}

// Fonctions administratives pour les rapports Universal Analytics
export function ga_universal_reports() {
  return fetch_api_json('GET', '/ga/universal/reports');
}

// Obtenir un rapport Universal Analytics direct pour un compte, une propriété et une période données
export function ga_universal_report_direct(account_id, property_id, start_date, end_date) {
  const qs = `start_date=${start_date}&end_date=${end_date}`;
  const encoded = encodeURIComponent(property_id);
  return fetch_api_json('GET', `/ga/universal/report/${account_id}/${encoded}?${qs}`);
}

// Fonctions liées aux métadonnées

// Obtenir des statistiques de métadonnées pour une propriété donnée
export function get_meta_stats(property_id, start_date, end_date) {
  const qs = `start_date=${start_date}&end_date=${end_date}`;
  return fetch_api_json('GET', `/property/${property_id}/meta/stats?${qs}`);
}

// Obtenir des réservations de métadonnées pour une propriété donnée
export function get_meta_bookings(property_id, start_date, end_date) {
  const url = process.env.REACT_APP_BACK_URL + '/property/' + property_id + '/meta/bookings?start_date=' + start_date + '&end_date=' + end_date;
  return axios.get(url, {
    withCredentials: true
  }).then(response => {
    return response.data;
  }).catch(err => {
    console.log(err);
    return err;
  });
}

// Fonction pour obtenir le coût des métadonnées
export function get_meta_cost(hotelCode, start_date, end_date) {
  return axios.post(process.env.REACT_APP_TOOLS_API_URL + 'metas/cost', {
      hotelCode: hotelCode.toString(),
      startDate: start_date,
      endDate: end_date
  }).then(response => {
    return response.data;
  }).catch(err => {
    return err;
  });
}

// Fonction pour obtenir un rapport Google Ads pour une propriété donnée
export function get_ads_report(property_id, start_date, end_date) {
  const qs = `start_date=${start_date}&end_date=${end_date}`;
  return fetch_api_json('GET', `/property/${property_id}/ads/report?${qs}`);
}