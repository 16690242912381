import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Tippy from "@tippyjs/react";
import axios from "axios";
import { logout } from "../../api";
import { selectUser } from "../../reducers/userSlice";
import Menu from "../menu/Menu";
import CustomSearchInput from "../custom/CustomSearchInput";
import Properties from "./properties/Properties";
import Customers from "./customers/Customers";
import MacroView from "./macro/MacroView";
import CustomPopover from "../custom/CustomPopover";
import "./AdminPanel.scss";

const AdminPanel = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState([]);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const isAdmin = user && user.role === 'admin';
  const sectionAffichage = isAdmin ? "main-app-right-part" : "main-app-right-part user-role";

  const { propertyId } = useParams();

  let headerTitre;
  if (propertyId) {
    const property = user?.properties?.find(prop => prop.id === parseInt(propertyId));
    headerTitre = property ? `${property.name}` : "Bienvenue";
  } else {
    if (user) {
      let messageBienvenue = "Bienvenue";
      if (user.firstname && user.name) {
        messageBienvenue += `, ${user.firstname} ${user.name} 👋`;
      } else if (user.firstname) {
        messageBienvenue += `, ${user.firstname} 👋`;
      } else if (user.name) {
        messageBienvenue += `, ${user.name} 👋`;
      }
      headerTitre = messageBienvenue;
    } else {
      headerTitre = "Bienvenue";
    }
  }  

  const handleDisconnect = useCallback(() => {
    logout().then(() => navigate("/login"));
  }, [navigate]);

  const handleSearchbarSelect = useCallback((property) => {
    navigate("/admin/properties/" + property.id);
  }, [navigate]);

  const togglePopover = useCallback(() => {
    setPopoverVisible(!popoverVisible);
  }, [popoverVisible]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/user`, { withCredentials: true });
        setSearchData(response.data.data);
      } catch (err) {
        if (isAdmin) {
          console.log("Veuillez contacter un administrateur, si vous avez des erreurs.");
        }
      }
    };

    fetchData();
  }, [dispatch, isAdmin]);

  // console.log(user);

  return (
    <div className="main-app admin-panel">
      <Menu />
      <div className={sectionAffichage}>
        <div className="main-navbar">
          <div className="navbar-title">
            {/* <h1>Bienvenue, {user.properties.find(prop => prop.id === parseInt(propertyId))?.name} 👋</h1> */}
            <h1>{headerTitre}</h1>
          </div>
          <div className="navbar-container">
            <div className="searchbar-container">
              <CustomSearchInput
                labelType="user"
                entity={JSON.parse(JSON.stringify(user.properties))}
                onChange={handleSearchbarSelect}
                value={"nom"}
                isFetchable={false}
              />
            </div>
            <Tippy
              content={<CustomPopover isVisible={popoverVisible} onToggle={togglePopover} onDisconnect={handleDisconnect} />}
              allowHTML={true}
              placement={"bottom-end"}
              offset={[0, 0]}
              visible={popoverVisible}
              onClickOutside={togglePopover}
              interactive={true}
              appendTo={"parent"}
            >
              <div className="user-item" onClick={togglePopover}>
                {user ? (
                  <span>
                    {user.firstname} {user.name}
                  </span>
                ) : (
                  <CircularProgress size={20} />
                )}
              </div>
            </Tippy>
          </div>
        </div>
        <div className="c-section">
          <Routes>
            <Route path="properties/*" element={<Properties />} />
            <Route path="customers/*" element={<Customers />} />
            <Route
              path="macro/*"
              element={<MacroView clients={searchData} />}
            />
            <Route path="*" element={<Navigate to="properties" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;