import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/userSlice";
import mainLogo from "../../assets/icons/altelis-logo.svg";
import logoProprietes from "../../assets/icons/home--black.svg";
import logoMacro from "../../assets/icons/macro-full-black.svg";
import logoClients from "../../assets/icons/users--black.svg";
import "../admin/AdminPanel.scss";
import "./Menu.scss";

const MenuLink = ({ to, logo, label, isActive }) => (
  <Link to={to} className="menu-link">
    <div className={`menu-sub-item ${isActive ? "active" : ""}`}>
      <div className="icon-menu">
        <img src={logo} alt="main-logo" width="20" />
      </div>
      <span id="label-menu">{label}</span>
    </div>
  </Link>
);

const Menu = () => {
  const location = useLocation();
  const user = useSelector(selectUser);
  const isAdmin = user && user.role === 'admin';
  const menuAffichage = isAdmin ? "main-menu" : "main-menu user-role";

  return (
    <div className={menuAffichage}>
      <div className="menu-top">
        <Link to="/" className="app-logo">
          <img src={mainLogo} alt="Logo Altelis Monitor" />
        </Link>
        <nav className="c-nav">
          {isAdmin && (
            <>
              <span className="span-category">Administration</span>
              <ul className="c-nav-ul">
                <MenuLink
                  to="/admin/properties"
                  logo={logoProprietes}
                  label="Propriétés"
                  isActive={location.pathname.includes("/admin/properties")}
                />
                <MenuLink
                  to="/admin/customers"
                  logo={logoClients}
                  label="Clients"
                  isActive={location.pathname.includes("/admin/customers")}
                />
                <MenuLink
                  to="/admin/macro"
                  logo={logoMacro}
                  label="Macro"
                  isActive={location.pathname.includes("/admin/macro")}
                />
              </ul>
            </>
          )}
          {!isAdmin && (
            <ul className="c-nav-ul">
              <MenuLink
                to="/properties"
                logo={logoProprietes}
                label="Propriétés"
                isActive={location.pathname.includes("/properties")}
              />
            </ul>
          )}
        </nav>
      </div>
      <div className="menu-bottom">
        <p className="menu-text-bottom">V1.1.5</p>
      </div>
    </div>
  );
};

export default Menu;