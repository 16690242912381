import React from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectUser} from "../../reducers/userSlice";

const PrivateRoute = ({ children }) => {
    const user = useSelector(selectUser);
    const location = useLocation();

    if(!user.role) return <Navigate to="/login" state={{from: location}} replace />

    return React.cloneElement(children, { user: user });
}

export default PrivateRoute;
