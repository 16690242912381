import React, {useMemo, useState} from 'react';
import {useTable, useSortBy, usePagination, useRowSelect} from 'react-table';
import dayjs from 'dayjs';
import emptyIcon from '../../assets/images/empty-list.png';
import {CircularProgress} from "@mui/material";
import {Link} from "react-router-dom";
import "../../assets/styles/App.scss";

export const formatFrancaisDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", options);
};

const CustomList = ({ pageSizeOptions = [20, 50, 100, 200], entity, columns, setSelectedColumns, setSelectedIds, refreshData, activeFilters, setData, isSelectable = true, isPaginable = true, isFetchable = true, enableLink = false, className = false, isMacro = false, isSmall = false}) => {

    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const data = useMemo(() => items, [items]);

    React.useEffect(() => {
        setIsLoaded(false);
        if(!isFetchable) {
            applyFilters(entity);
            setIsLoaded(true);
        }
    }, [entity, refreshData, setData, activeFilters]);

    const applyFilters = (data) => {
        let filteredData = [...data];
        activeFilters && activeFilters.forEach(column => {

            let columnData = [];
            if(column.icon !== 'calendar') {
                column.selectedValues && column.selectedValues.forEach(value => {
                    switch(true) {
                        case column.accessor === 'project.name' || column.accessor === 'customer.name' || column.accessor === 'provider' || column.accessor === 'sender':
                            columnData = columnData.concat(data.filter(row => row[column.accessor.split('.')[0]].name === value.value))
                            break;
                        case column.accessor === 'project_managers.0':
                            columnData = columnData.concat(data.filter(row => row.project_managers[0].firstName + ' ' + row.project_managers[0].lastName === value.value))
                            break;
                        default:
                            columnData = columnData.concat(data.filter(row => row[column.accessor] === value.value))
                            break;
                    }
                });

            } else {
                columnData = data.filter(row => {
                    if(row[column.accessor]) {
                        if(column.startDate && column.stopDate) {
                            return dayjs(row[column.accessor]).isBetween(column.startDate, column.stopDate);
                        } else if (column.startDate && !column.stopDate) {
                            return dayjs(row[column.accessor]).isSameOrAfter(column.startDate);
                        } else if (!column.startDate && column.stopDate) {
                            return dayjs(row[column.accessor]).isSameOrBefore(column.stopDate);
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                });
            }
            if((column.icon === 'calendar' && (column.startDate || column.stopDate)) || columnData.length > 0) {
                filteredData = filteredData.filter(row => columnData.includes(row))
            }
        })
        setItems(filteredData);
    }

    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize, selectedRowIds }} = useTable({ columns, data, initialState: { pageSize: isMacro ? 100 : (isSmall ? 5 : 20) } },
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            isSelectable && hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        });

    React.useEffect(() => {
        if(typeof setSelectedColumns === 'function' && typeof setSelectedIds === 'function') {
            setSelectedColumns(Object.keys(selectedRowIds).length);
            setSelectedIds(selectedFlatRows.map((row) => { return row.original.id }));
            setPageSize(10);
        }
    }, [selectedRowIds, setSelectedColumns, setPageSize, selectedFlatRows, setSelectedIds]);

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])

            return (
                <label className="checkbox-container">
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                    <span className="checkmark"/>
                </label>
            )
        }
    )     

    return isLoaded ? (
        <>
        <div className={` ${isSelectable ? 'list-container' : 'list-container non-selectable'} ${className ? className : null}`}>
            <table {...getTableProps()}>
                <thead>
                { headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        { headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={`${column.icon ? 'column-icon ' + column.icon : '' + (column.id === 'selection' ? 'checkbox-fixed-size' : '')} ${column.classname ? column.classname : null}`}>
                                {column.render('Header')}
                                {column.isSorted ? column.isSortedDesc ? <span className='sorted' /> : <span className='sorted-desc' /> : ''}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                { items.length > 0 ? (
                    <tbody {...getTableBodyProps()}>
                    { page.map((row) => {
                        prepareRow(row)
                        return <tr {...row.getRowProps()}>
                            { row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>
                                    { enableLink ? (cell.column.id === 'selection' ? cell.render('Cell') : <Link to={ cell.row.original.id.toString() } >{cell.render('Cell')}</Link>) : <div className={`${cell.column.class ? "border-item" : null}`}>{cell.render('Cell')} {cell.column.attribut ? cell.column.attribut : null}</div> }
                                </td>
                            })}
                        </tr>
                    })}
                    </tbody>
                ) : (
                    <tbody>
                    <tr>
                        <td colSpan={7}>
                            <div className='empty-list-container'>
                                <img src={emptyIcon} alt='icon' width={120} />
                                <span>Il n'y a encore aucun élément dans cette liste !</span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                )}

            </table>
        </div>
            {isPaginable && items.length > 0 && (
                <div className="pagination-container">
                    <div className="current-page-info">
                        Page <strong>{pageIndex + 1} sur {pageOptions.length}</strong>
                    </div>
                    <div className="page-switcher">
                        <button className="pagination-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{"<<"}</button>
                        <button className="pagination-button" onClick={previousPage} disabled={!canPreviousPage}>{"<"}</button>
                        <button className="pagination-button" onClick={nextPage} disabled={!canNextPage}>{">"}</button>
                        <button className="pagination-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{">>"}</button>
                    </div>
                    <div className="rows-by-page">
                        <span className="padding-right">Lignes par page</span>
                        <select 
                            value={pageSize}
                            className="pagination-button select"
                            onChange={e => setPageSize(Number(e.target.value))}>
                            {pageSizeOptions.map(size => (
                                <option key={size} value={size}>{size}</option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
        </>
    ) : (
        <div className='loader-container'><CircularProgress size={30} /></div>
    );
}

export default CustomList;