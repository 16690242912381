import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import CustomList from "../../../custom/CustomList";
import { formatFrancaisDate } from "../../../custom/CustomList";
import CustomLoader from "../../../custom/CustomLoader";
import "./TabGestionHotel.scss";

const TabGestionHotel = ({ user, property }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [logoUrl, setLogoUrl] = useState("");

  const fakeInvoices = [
    { id: 1, montant: 500, date: "2023-10-01" },
    { id: 2, montant: 750, date: "2023-09-15" },
    { id: 3, montant: 600, date: "2023-08-28" },
    { id: 4, montant: 1253, date: "2023-08-28" },
    { id: 5, montant: 2310, date: "2023-08-28" },
    { id: 6, montant: 8790, date: "2023-12-28" },
  ];

  const columns = React.useMemo(() => [
    {
      Header: "Référence",
      accessor: "id",
      icon: "id",
    },
    {
      Header: "Date",
      accessor: "date",
      icon: 'calendar',
      Cell: ({ cell: { value } }) => formatFrancaisDate(value),
    },
    {
      Header: "Montant",
      accessor: "montant",
      class: "border-item",
      attribut: "€",
      icon: 'euro',
      Cell: ({ cell: { value } }) => <span>{Number(value).toLocaleString('fr-FR', { maximumFractionDigits: 2 })}</span>
    }
  ], []);

  useEffect(() => {
    const recupererTokenEtDonnees = async () => {
      try {
        const token = await obtenirToken();
        const donneesChatbot = await fetcherDonneesChatbot(token);
        setLogoUrl(donneesChatbot.logoUrl);
      } catch (erreur) {
        console.error("Erreur dans la récupération de l'image", erreur);
      }
    };
    recupererTokenEtDonnees();
  }, []);

  const obtenirToken = async () => {
    try {
      const email = process.env.REACT_APP_API_TOOLS_ID;
      const password = process.env.REACT_APP_API_TOOLS_ID_PWD;

      const reponseLogin = await axios.post(
        "https://tools.altelis.com/api/login",
        { email, password },
        { headers: { "Content-Type": "application/json" } }
      );

      setTimeout(() => setIsLoading(false), 2000);
      return reponseLogin.data.token;
    } catch (erreur) {
      console.error(
        "Erreur de connexion avec ALTELIS TOOLS API",
        erreur.response ? erreur.response.data : erreur.message
      );
      setIsLoading(false);
      throw erreur;
    }
  };

  const fetcherDonneesChatbot = async (token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const reponse = await axios.get(
      `https://tools.altelis.com/api/engines/${property.meta_hotel_code}/chatbot`,
      config
    );
    return reponse.data;
  };

  return (
    <div className="gestion-hotel-wrapper">
      <div className="header-title gestion-hotel">
        <h2>Gérer mon hôtel</h2>
      </div>
      <div className="gestion-hotel-row">
        {/* <div className="c-card hotel">
          <div className="c-card-content-wrapper">
            {isLoading ? (
              <CustomLoader type="hotel" />
            ) : (
              <div className="c-card-content-wrapper-image fade-in">
                {logoUrl && <img src={logoUrl} alt="Chatbot Logo" />}
              </div>
            )}
            <p className="property-hotel">{property.name}</p>
            <p>{user.firstname || "Non renseigné"} {user.name || "Non renseigné"}</p>
          </div>
        </div> */}
        <div className="gestion-hotel-invoices">
          <div className="gestion-heading-wrapper">
            <h2>Vos factures</h2>
            <Link className="btn-contact" to="/contacter-support">
              Contacter le support
            </Link>
          </div>
          <CustomList
            columns={columns}
            entity={fakeInvoices}
            isFetchable={false}
            isSelectable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default TabGestionHotel;