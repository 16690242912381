import { CircularProgress } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import dayjs from "dayjs";
import localeFr from "dayjs/locale/fr";
import duration from "dayjs/plugin/duration";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { current_user } from "./api";
import "./assets/styles/App.scss";
import AdminPanel from "./components/admin/AdminPanel";
import Login from "./components/login/Login";
import PasswordForgot from "./components/login/PasswordForgot";
import PrivateRoute from "./components/routes/PrivateRoute";
import { setInitial } from "./reducers/filterSlice";
import { setUser } from "./reducers/userSlice";

dayjs.locale(localeFr);
dayjs.extend(duration);

const App = () => {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    setLoaded(false);
    current_user()
      .then((response) => {
        if (!response.request.responseURL.includes("log_in")) {
          const user = response.data;
          dispatch(
            setUser({
              id: user.id,
              email: user.email,
              name: user.name,
              firstname: user.firstname,
              role: user.role,
              properties: user.properties,
            })
          );
          dispatch(setInitial());
          setLoaded(true);
          user.properties.length > 1
            ? navigate("/admin")
            : navigate("/details");
        } else {
          setLoaded(true);
          navigate("/login");
        }
      })
      .catch(() => {
        setLoaded(true);
        navigate("/login");
      });
  }, []);

  return loaded ? (
    <Routes>
      <Route path="admin/*" element={<PrivateRoute><AdminPanel /></PrivateRoute>}/>
      <Route path="details" element={<AdminPanel />} />
      <Route exact path="login" element={<Login />} />
      <Route exact path="forgot-password" element={<PasswordForgot />} />
      <Route path="*" element={<Navigate to="admin" />} />
    </Routes>
  ) : (
    <div className="loader-container">
      <CircularProgress size={30} />
    </div>
  );
};

export default App;