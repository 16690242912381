import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { all_reports } from "../../api";
import { selectEndDate, selectStartDate } from "../../reducers/filterSlice";
import {selectUser} from '../../reducers/userSlice';
import { globalAds, globalAll, globalMeta } from "../admin/functionGlobal";
import SelectBar from "./SelectBar";
import TabGestionHotel from "./tabs/TabGestionHotel/TabGestionHotel";
import TabLivie from "./tabs/TabLivie/TabLivie";
import TabReservations from "./tabs/TabReservations/TabReservations";
import CustomExportPDF from "../custom/CustomExportPdf";
import StarsLivieAI from "../../assets/icons/livie-ai--stars.svg";
import dayjs from "dayjs";
import "./tabs/CustomTabs.scss";

const CustomerPanel = ({ adminMode }) => {
  const [tabValue, setTabValue] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [property, setProperty] = useState(null);
  const [dateRanges, setDateRanges] = useState([]);
  const [api, setApi] = useState({});
  const user = useSelector(selectUser);
  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);
  const params = useParams();
  const livieTab = property && property.livie_id ? "Livie by Altelis" : null;
  const livieTabIndex = 1;
  const citiaTab = property && property.livie_id ? "Citia by Ageelity" : null;

  useEffect(() => {
    if (user) {
        const newProperty = adminMode 
            ? user.properties.find(p => p.id === parseInt(params.propertyId)) 
            : user.properties[0];
            setProperty(newProperty);
    }
}, [user, adminMode, params.propertyId]);

const loadData = useCallback(async () => {
  setIsLoaded(false);
  if (!property.id) {
      setIsLoaded(true);
      return;
  }
  try {
      const response = await all_reports(
          property.id,
          dayjs(startDate).format("YYYY-MM-DD"),
          dayjs(endDate).format("YYYY-MM-DD")
      );

      setDateRanges(response.data.ads ? response.data.ads.map(row => row.range) : []);
      setApi({
          analytics: response.data.ga?.data,
          ads: globalAds(response.data.ads),
          meta: globalMeta(response.data.meta, response.data.meta_cost),
          all: globalAll(response.data),
      });
      setIsLoaded(true);
  } catch (error) {
      console.log("Erreur :", error.response);
      setIsLoaded(true);
  }
}, [property, startDate, endDate]);

const ReloadData = useCallback(() => {
  setIsLoaded(false);
  loadData();
}, [loadData]);

useEffect(() => {
  if (property) {
    ReloadData();
  }
}, [property, ReloadData]);

  const handleTabChange = (tabIndex) => {
    setTabValue(tabIndex);
    if (tabIndex === 0) loadData();
  };
  
  return (
    <div className="main-component">
      <div className="tab-container">
        <div className="tab-container-left">
          {["Réservations", livieTab].map((tab, index) => (
            tab && (
              <button
                key={tab}
                className={`tab-link ${tabValue === index ? "tab-active" : ""}`}
                onClick={() => handleTabChange(index)}
              >
                <span>{tab}</span>
                {tab === livieTab && <img src={StarsLivieAI} alt="Livie AI" />}
              </button>
            )
          ))}
        </div>
        <CustomExportPDF dateRanges={dateRanges} property={property} />
        <hr></hr>
        <SelectBar loadData={ReloadData} dateRanges={dateRanges} hideFlexButton={tabValue === livieTabIndex} tabLivieAffichage={tabValue === livieTabIndex} />
      </div>
  
      {tabValue === 0 && property && <TabReservations api={{ ...api, isLoaded, property }} adminMode={adminMode} />}
      {tabValue === 1 && property && property.livie_id && <TabLivie utilisateurs={api.analytics?.[1]?.totals?.[0] || 0} property={property} startDate={startDate} endDate={endDate} />}
      {/* {tabValue === 2 && property && property.livie_id && <TabLivie utilisateurs={api.analytics?.[1]?.totals?.[0] || 0} property={property} startDate={startDate} endDate={endDate} />} */}
      {tabValue === 2 && property && <TabGestionHotel user={user} property={property} />}
    </div>
  );
};

export default CustomerPanel;