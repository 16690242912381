import React, { useState } from "react";
import "./Login.scss";
import LogoAltelis from "../../assets/icons/logo-altelis-v2.svg";
import IlluLogin from "../../assets/images/illu-login.gif";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/userSlice";
import axios from "axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [redirectToReferrer] = useState(false);
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  // TODO : à revoir
  if (redirectToReferrer === true) {
    return user.role !== "admin" ? (
      <Navigate to={`/admin/monitor/details/${user.client_id_api}`} />
    ) : (
      <Navigate to={"/admin/details"} />
    );
  }

  const handleSubmit = (event) => {
    if (event.type === "click" || event.key === "Enter") {
      setLoader(true);
      axios
        .post(
          process.env.REACT_APP_BACK_URL + "/accounts/log_in/",
          {
            email: email,
            password: password,
          },
          {
            withCredentials: true,
          }
        )
        .then(() => {
          navigate(0);
        })
        .catch((err) => {
          setError("Identifiants incorrects");
          setLoader(false);
          console.log(err);
        });
    }
  };

  return (
    <div className="main-login">
      <div className="login-background" />
      <div className="login-container">
        <div className="login-left">
          <img src={IlluLogin} alt="Illustration page login"></img>
          <p>
            Boostez votre hôtel avec des statistiques incontournables Direct,
            Livie, Google Ads, Métas...
          </p>
        </div>
        <div className="login-form">
          <div className="img-container">
            <img src={LogoAltelis} alt="Logo Altelis Monitor"/>
          </div>
          <div className="login-form-right">
            <h1>Bienvenue sur Altelis Monitor 👋</h1>
            <p className="welcome-message">Veuillez rentrer vos identifiants pour vous connecter</p>
            {error ? <p className="login-error">{error}</p> : null}
            <div className="login-input-group">
              <label>
                Email <span className="required"> *</span>
              </label>
              <input
                type="email"
                placeholder="Saisissez votre adresse email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError("");
                }}
                onKeyUp={(e) => handleSubmit(e)}
              />
            </div>
            <div className="login-input-group">
              <label>
                Mot de passe <span className="required"> *</span>
              </label>
              <input
                type="password"
                placeholder="Saisissez votre mot de passe"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError("");
                }}
                onKeyUp={(e) => handleSubmit(e)}
              />
            </div>
            <Link to='/forgot-password' className='forgot-password'>Mot de passe oublié ?</Link>
            <div className="login-button-container">
              {loader ? (
                <button
                  className="btn btn-primary login-button-loading"
                  disabled
                >
                  <CircularProgress size={20} className="white-loader" />
                </button>
              ) : (
                <button
                  className="btn btn-primary login-button"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Se connecter
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
