import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import Modal from "react-modal";
import dayjs from "dayjs";
import CustomList from "../../../custom/CustomList";
import { formatFrancaisDate } from "../../../custom/CustomList";
import NbConversations from "../../../../assets/images/chatbot-livie/nb_conversations.svg";
import NbConversationsEtrangere from "../../../../assets/images/chatbot-livie/nb_conversations_etrangere.svg";
import NbClicksChatbot from "../../../../assets/images/chatbot-livie/click-chatbot.svg";
import TauxUtilisation from "../../../../assets/images/chatbot-livie/taux-utilisation.svg";
import CoutToken from "../../../../assets/images/chatbot-livie/cout-token.svg";
import CustomTooltip from "../../../custom/CustomTooltip";
import LogoLivieAI from "../../../../assets/icons/livie-ai--stars.svg";
import LogoLivieHeader from "../../../../assets/icons/livie-ai--header.svg";
import LogoLivieChatbot from "../../../../assets/icons/livie-ai--chatbot.svg";
import LivieChatbotClose from "../../../../assets/icons/livie-ai-chatbot--close.svg";
import CustomLoader from "../../../custom/CustomLoader";

import "./TabLivie.scss";

Modal.setAppElement('#root');

const TabLivie = ({ tooltip, utilisateurs, property, startDate, endDate }) => {
  const [dataAPI, setDataAPI] = useState(null);
  const [conversationDetails, setConversationDetails] = useState([]);
  const [pourcentageLangueEtrangere, setPourcentageLangueEtrangere] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [conversationIdLivie, setconversationIdLivie] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const connexionsLivie = utilisateurs
    ? dataAPI
      ? (dataAPI.nb_real_conversation / utilisateurs) * 100
      : 0
    : 0;

  const columns = useMemo (
    () => [
      {
        Header: "Id",
        accessor: "chatId",
        icon: "id",
      },
      {
        Header: "Date de la conversation",
        accessor: "updatedAt",
        icon: "calendar",
        Cell: ({ cell: { value } }) => formatFrancaisDate(value),
      },
      {
        Header: "Extrait de la conversation",
        accessor: "userQuestion",
        icon: "conversation",
        Cell: ({ cell: { value } }) => (
          <div className="conversation-cell">
            {value && value.charAt(0).toUpperCase() + value.slice(1)}
          </div>
        ),
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => {
          return (
            <button onClick={() => ouvertureModale(row.original.chatId)} className="view-conversation">
              <img src={LogoLivieAI} alt="Icone Livie AI" />
              Voir la conversation
            </button>
          );
        },
      }
    ],
    []
  );

  const conversationLivie = (conversationId) => {
    if (!conversationIdLivie) return <p>Aucune conversation sélectionnée.</p>;

    const conversation = conversationDetails.find(c => c.chatId === conversationId);
    if (!conversation) return <p>Conversation introuvable.</p>;

    return conversation.events.map((event, index) => {
      const isUserInput = event.type === "user_input";
      const text = event.params?.input || event.params?.text || event.params?.result || "";
      const texteFormat = text.split(/(\*\*.*?\*\*)/).map((part, index) => 
        part.match(/^\*\*(.*?)\*\*$/) ? <strong key={index}>{part.slice(2, -2)}</strong> : part
      );

      return (
        <div key={index} className={isUserInput ? "modal-livie-text-users" : "modal-livie-text-ai"}>
          {!isUserInput && <img src={LogoLivieChatbot} alt="Logo Livie AI" />}
          <p>{texteFormat}</p>
        </div>
      );
    });
  };

  const ouvertureModale = (chatId) => {
    setconversationIdLivie(chatId);
    setIsModalOpen(true);
  };

  const fermetureModale = () => {
    setIsModalOpen(false);
    setconversationIdLivie(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!property || !property.livie_id) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      try {
        const dateDebut = dayjs(startDate).format("YYYY-MM-DD");
        const dateFin = dayjs(endDate).add(1, 'day').format("YYYY-MM-DD");
        const baseURL = process.env.REACT_APP_CHATBOT_URL;
        const url = `${baseURL}${property.livie_id}?date1=${dateDebut}&date2=${dateFin}`;
        console.log("URL Prod Livie :", url);
        const response = await axios.get(url);
        if (response.data && response.data.data) {
          const hotelStats = response.data.data[0];

          let conversationDetails = response.data.data.flatMap((d) =>
            d.chats.map((chat) => ({
              chatId: chat.chat.id,
              updatedAt: chat.chat.updated_at,
              userQuestion:
                chat.events.find((event) => event.type === "user_input")?.params
                  .input || "",
              events: chat.events,
            }))
          );

          conversationDetails.sort((a, b) =>
            dayjs(b.updatedAt).diff(dayjs(a.updatedAt))
          );

          setDataAPI(hotelStats);
          setConversationDetails(conversationDetails);
          setTimeout(() => setIsLoading(false), 250);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données de l'API",
          error
        );
        setIsLoading(false);
      }
    };

    fetchData();
  }, [property, startDate, endDate]);

  useEffect(() => {
    if (dataAPI) {
      const nbConversations = dataAPI.nb_real_conversation;
      const nbConversationsEtrangeres = dataAPI.nb_real_conversations_foreigns;
      if (nbConversations > 0) {
        const pourcentage = (nbConversationsEtrangeres / nbConversations) * 100;
        setPourcentageLangueEtrangere(Math.round(pourcentage));
      }
    }
  }, [dataAPI]);

  const donneesLivie = [
    {
      id: "nb_real_conversation",
      title: "Nb. de conversations",
      value: dataAPI ? dataAPI.nb_real_conversation : "Chargement...",
      tooltip: "Indique le nombre total de conversations engagées via le chatbot",
      image: NbConversations,
      imageAlt: "Illustration Nb. de conversations Livie",
    },
    {
      id: "pourcentage_langue_etrangere",
      title: "Pourcentage de conversations en langue étrangère",
      value: dataAPI ? `${pourcentageLangueEtrangere} %` : "Chargement...",
      tooltip: "Montre le pourcentage des conversations menées dans une langue autre que le français",
      image: NbConversationsEtrangere,
      imageAlt: "Illustration Nb. de conversations en langue étrangère",
    },
    {
      id: "nb_clicks",
      title: "Nb. de clicks depuis le chatbot",
      value: dataAPI ? dataAPI.nb_clicks : "Chargement...",
      tooltip: "Compte le nombre de clics réalisés depuis le chatbot, indiquant l'interaction des utilisateurs",
      image: NbClicksChatbot,
      imageAlt: "Illustration Nb. de réservations depuis le chatbot",
    },
    // {
    //   id: "nb_token",
    //   title: "Tokens consommé(s) sur le mois en cours",
    //   // value: dataAPI ? dataAPI.nb_token : 34944,
    //   // progressValue: dataAPI ? dataAPI.nb_token : 34944,
    //   value: 67932,
    //   progressValue: 67932,
    //   progressMax: 100000,
    //   tooltip: "Affiche le nombre de tokens utilisés ce mois-ci par rapport au total alloué",
    // },
    {
      id: "utilisation_chatbot",
      title: "Taux d'utilisation du chatbot",
      value: `${connexionsLivie.toFixed(2)} %`,
      tooltip: "Indique le pourcentage d'utilisation du chatbot en comparaison avec le trafic total du site",
      image: TauxUtilisation,
      imageAlt: "Illustration Taux de conversion des visiteurs",
    },
    // {
    //   id: "cout_token",
    //   title: "Coût pour 1000 tokens de prompts (USD)",
    //   value: "0.03 $",
    //   tooltip: "Indique le coût estimé de l'utilisation de 1000 tokens de prompts en USD.",
    //   image: CoutToken,
    //   imageAlt: "Illustration Coût pour 1000 tokens de prompts (USD)",
    // }
  ];

  return (
    <div className="tab-livie">
      <div className="header-title stats-livie">
        <h2>Statistiques Livie</h2>
        <p>Découvrez une vision détaillée des interactions avec Livie, offrant un aperçu clair des performances et des tendances de conversation</p>
      </div>
        <section className="section-livie-wrapper">
          <div className="article-wrapper">
          {isLoading ? (
            donneesLivie
              .filter((item) => item.type !== "doughnut")
              .map((item) => (
                <div key={item.id} className="loading-data">
                  <CustomLoader />
                </div>
              ))
          ) : (
              <>
                {donneesLivie
                  .filter((item) => item.id !== "nb_token" && item.type !== "doughnut")
                  .map((item) => (
                    <article
                      key={item.id}
                      className={`c-card initial fade-in`}
                      id={item.id}
                    >
                      <div className="c-card-content">
                        <div className="c-card_top livie">
                          {tooltip !== false ? (
                            <CustomTooltip text={item.tooltip} />
                          ) : null}
                          <span className="c-card_title">{item.title}</span>
                        </div>
                        <p className="c-card_data">{item.value}</p>
                      </div>
                      <img src={item.image} alt={item.imageAlt} />
                    </article>
                  ))}
                {donneesLivie
                  .filter((item) => item.id === "nb_token")
                  .map((item) => (
                    <article
                      key={item.id}
                      className={`c-card initial fade-in`}
                      id={item.id}
                    >
                      <div id="nb_tokens_article">
                        <div className="c-card-content">
                          <div className="c-card_top livie">
                            {tooltip !== false ? (
                              <CustomTooltip text={item.tooltip} />
                            ) : null}
                            <span className="c-card_title">{item.title}</span>
                          </div>
                          <div className="c-card-data_percentage">
                            <div className="c-progress-bar">
                              <span>
                                {dataAPI && dataAPI.nb_token
                                  ? `${dataAPI.nb_token} %`
                                  : item.value}
                              </span>
                              <progress
                                id="nb_tokens"
                                value={
                                  dataAPI && dataAPI.nb_token
                                    ? dataAPI.nb_token
                                    : item.value
                                }
                                max={item.progressMax}
                              ></progress>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  ))}
              </>
            )}
          </div>
        </section>
      {!isLoading && (
        <div className="table-wrapper-livie fade-in">
          <div className="header-title chat-livie">
            <h3>Vos conversations Livie</h3>
            <p>Explorez les conversations individuelles pour une compréhension approfondie des besoins et questions des utilisateurs, enrichissant ainsi votre stratégie de communication</p>
          </div>
          <CustomList
            key={JSON.stringify(conversationDetails)}
            columns={columns}
            entity={conversationDetails}
            pageSizeOptions={[5, 10]}
            isSmall={true}
            isFetchable={false}
            isSelectable={false}
          />
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={fermetureModale}
        contentLabel="Détails de la conversation"
      >
        <div className="modal-livie-wrapper">
          <div className="modal-livie-header">
            <div className="modal-livie-header-icon">
              <img src={LogoLivieHeader} alt="Logo Livie AI Header"></img>
              <p>
                Conversation #
                {conversationIdLivie}
              </p>
            </div>
            <button onClick={fermetureModale}>
              <img src={LivieChatbotClose} alt="Icone Livie Close"></img>
            </button>
          </div>
          <div className="modal-livie-content">
            {conversationLivie(conversationIdLivie)}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TabLivie;