import React, {useState} from "react";
import Select from "react-select";
import CustomOption from "./CustomOption";

const CustomSearchInput = ({entity, isMultiSearch, labelType, onChange, className, value, isFetchable = true }) => {

    const [items, setItems] = useState();

    React.useEffect(() => {
        setItems(entity);
    }, [entity, value]);

    React.useEffect(() => {
        if(items) {
            items.map((item) => {
                item.value = item.Id;
                switch (labelType) {
                    case 'user':
                        item.value = item.id
                        item.label = item.name;
                        break;
                    default:
                        // Pourquoi il n'y a qu'un case ??
                        break;
                }
                return item;
            })
        }
    }, [items, labelType]);

    return (
        <Select
            isMulti={isMultiSearch}
            name="colors"
            isRequired={true}
            options={items}
            onChange={onChange}
            value={value}
            className={ isMultiSearch ? "basic-multi-select multi-search-input " : "basic-single single-search-input " + className}
            classNamePrefix="select"
            placeholder={"Rechercher une propriété..."}
            components={{ Option: CustomOption }}
        />
    )
}

export default CustomSearchInput;
