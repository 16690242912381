import React, { useMemo } from "react";
import { formatFrancaisDate } from "../custom/CustomList";
import CustomList from "../custom/CustomList";

const Reservations = ({data, more}) => {

    const columns = useMemo (
        () => [
            {
                Header: 'Référence',
                accessor: 'reference',
                icon: 'role'
            },
            {
                Header: 'Date d\'arrivée',
                accessor: 'date_arrivee',
                icon: 'calendar',
                Cell: ({ cell: { value } }) => formatFrancaisDate(value),
            },
            {
                Header: 'Date de départ',
                accessor: 'date_depart',
                icon: 'calendar',
                Cell: ({ cell: { value } }) => formatFrancaisDate(value),
            },
            {
                Header: 'Montant de Réservations',
                accessor: 'montant_resa',
                class: "border-item",
                attribut: "€",
                icon: 'euro',
                Cell: ({cell: {value}}) => (<span>{Number(value).toLocaleString('fr-FR', {maximumFractionDigits: 2})}</span>)
            }
        ]
    )

    return (
        <div className={`transactions-container input-orders-container ${!data.sample ? "" : "sample"}`}>
            {data ? (
                <CustomList 
                    entity={data["data_0"]} 
                    columns={columns.filter(column => !column.hide)} 
                    isFetchable={false} 
                    isSelectable={false} 
                    isPaginable={true}
                    pageSizeOptions={[5, 10]}
                    isSmall={true}
                />
            ) : null}
        </div>
    )
}

export default Reservations;
