import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { selectUser } from "../../../../reducers/userSlice";
import { selectPeriod } from "../../../../reducers/filterSlice";
import CustomTooltip from "../../../custom/CustomTooltip";
import Analytics from "../../Analytics";
import Ads from "../../Ads";
import Meta from "../../Meta";
import AmountAll from "../../AmountAll";

const TabReservations = ({ api, adminMode }) => {
  const user = useSelector(selectUser);
  const period = useSelector(selectPeriod);
  const [localApi, setLocalApi] = useState(api);

  useEffect(() => {
    if (api && api.isLoaded) {
      setLocalApi(api);
    }
  }, [api]);

  const messageBienvenue = () => (
    <div>
      <div className="view-header">
        <div className="header-title hello">
          <h1>Bienvenue, {user.name}</h1>
        </div>
        <div className="c-tags">
          <span className={`custom-tag ${!user.properties.analytics ? "active" : "archived"}`}>Google Analytics</span>
          <span className={`custom-tag ${!user.properties.ads ? "active" : "archived"}`}>Google Ads</span>
          <span className={`custom-tag ${!user.properties.meta ? "active" : "archived"}`}>Métas</span>
        </div>
      </div>
      <div className="waiting-order-warning customer-info">
        <p>
          Altelis est ravi de vous accueillir sur votre nouvelle interface Monitor ! 🎉<br />
          Vous retrouverez ici l'ensemble des données récoltées sur nos différents services.<br />
          Si vous rencontrez des difficultés à comprendre la signification des données affichées, n'hésitez pas à survoler le petit icône <CustomTooltip text="Je suis là pour vous aider !" /> d'explication !
        </p>
      </div>
    </div>
  );

  const content = useMemo(() => {
    if (!localApi.isLoaded) {
      return <div className="loader-container"><CircularProgress size={30} /></div>;
    }

    return (
      <div className="main-router-content">
        <div className="view-container">
          {!adminMode && messageBienvenue()}
          <div className="transactions-container parts">
            <div className="header-title parts">
              <h2>Réservations directes</h2>
            </div>
            <AmountAll data={api.all} period={period} />
          </div>
          <div className="transactions-container analytics">
            <div className="header-title analytics">
              <h2>Google Analytics</h2>
            </div>
            <Analytics data={api.analytics} period={period} property={api.property} />
          </div>
          <div className="transactions-container adwords">
            <div className="header-title ads">
              <h2>Google Ads</h2>
            </div>
            <Ads data={api.ads} period={period} />
          </div>
          <div className="transactions-container metas">
            <div className="header-title meta">
              <h2>Métas</h2>
            </div>
            <Meta meta={api.meta} period={period} property={api.property} />
          </div>
        </div>
      </div>
    );
  }, [localApi, user, period, adminMode]);

  return content;
};

export default TabReservations;