import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import CustomList from "../../custom/CustomList";
import CustomTableItem from "../../custom/CustomTableItem";
import {all_reports, get_meta_cost, macro_reports} from "../../../api";
import {useSelector, useDispatch} from "react-redux";
import {selectEndDate, selectFilters, selectStartDate, setInitial} from "../../../reducers/filterSlice";
import dayjs from "dayjs";
import {globalAds, globalMeta, macroAnalytics, macroAds, macroMeta, globalAll} from "../functionGlobal";
import CustomTag from "../../custom/CustomTag";
import {selectUser} from "../../../reducers/userSlice";
import SelectBar from "../../customers/SelectBar";
import CustomMultiSelect from "../../custom/CustomMultiSelect";

const MacroView = () => {

    const { customerId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const [api, setApi] = useState([]);
    const dispatch = useDispatch();
    const filters = useSelector(selectFilters);
    const [dateRanges, setDateRanges] = useState([]);
    const [filtersList, setFiltersList] = useState([]);
    var pageLoad = 0;

    const user = useSelector(selectUser);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
                icon: 'id',
                sortType: 'basic',
                classname: 'hotel-id',
            }, {
                Header: 'Hotel Code',
                sortType: 'basic',
                accessor: 'hotelCode',
                icon: 'user',
            },{
                Header: 'Nom',
                accessor: 'name',
                classname: 'hotel-name',
                sortType: 'basic',
                icon: 'user',
            },{
                Header: 'Utilisateurs',
                accessor: `analytics`,
                classname: 'analytics analytics-bg',
                sortType: (a, b) => {
                    a.values['analytics'] === null  ? a = 0 : a = a.values['analytics']['data_0'];
                    b.values['analytics'] === null  ? b = 0 : b = b.values['analytics']['data_0'];
                    if (a < b) return -1
                    else if (a > b) return 1;
                    else return 0;

                },
                Cell: ({cell: {value}}) => (<CustomTableItem value={value}/>)
            },{
                Header: 'Réservations',
                accessor: `ads.data[0]`,
                classname: 'ads-bg',
                sortType: (a, b) => {
                    a.values['ads.data[0]'] === undefined  ? a = 0 : a = a.values['ads.data[0]']['data_0'];
                    b.values['ads.data[0]'] === undefined  ? b = 0 : b = b.values['ads.data[0]']['data_0'];
                    if (a < b) return -1
                    else if (a > b) return 1;
                     return 0;

                },
                Cell: ({cell: {value}}) => (<CustomTableItem value={value} sign={"€"}/>)
            },{
                Header: 'ROI',
                accessor: `ads.data[1]`,
                classname: 'ads ads-bg',
                sortType: (a, b) => {
                    a.values['ads.data[1]'] === undefined  ? a = 0 : a = a.values['ads.data[1]']['data_0'];
                    b.values['ads.data[1]'] === undefined  ? b = 0 : b = b.values['ads.data[1]']['data_0'];
                    if (a < b) return -1
                    else if (a > b) return 1;
                    return 0;

                },
                Cell: ({cell: {value}}) => (<CustomTableItem value={value} />)
            },{
                Header: 'Appels',
                accessor: `ads.data[2]`,
                classname: 'ads-bg',
                sortType: (a, b) => {
                    a.values['ads.data[2]'] === undefined  ? a = 0 : a = a.values['ads.data[2]']['data_0'];
                    b.values['ads.data[2]'] === undefined  ? b = 0 : b = b.values['ads.data[2]']['data_0'];
                    if (a < b) return -1
                    else if (a > b) return 1;
                    return 0;

                },
                Cell: ({cell: {value}}) => (<CustomTableItem value={value} />)
            },{
                Header: 'Réservations',
                accessor: `meta.data[0]`,
                classname: 'meta meta-bg',
                sortType: (a, b) => {
                    a.values['meta.data[0]'] === undefined  ? a = 0 : a = a.values['meta.data[0]']['data_0'];
                    b.values['meta.data[0]'] === undefined  ? b = 0 : b = b.values['meta.data[0]']['data_0'];
                    if (a < b) return -1
                    else if (a > b) return 1;
                    return 0;

                },
                Cell: ({cell: {value}}) => (<CustomTableItem value={value} sign={"€"}/>)
            },
            {
                Header: 'ROI',
                accessor: `meta.data[1]`,
                classname: 'meta-bg',
                sortType: (a, b) => {
                    a.values['meta.data[1]'] === undefined && isNaN(a.values['meta.data[1]'])  ? a = 0 : a = a.values['meta.data[1]']['data_0'];
                    b.values['meta.data[1]'] === undefined && isNaN(b.values['meta.data[1]']) ? b = 0 : b = b.values['meta.data[1]']['data_0'];
                    if (a < b) return -1
                    else if (a > b) return 1;
                    return 0;

                },
                Cell: ({cell: {value}}) => (<CustomTableItem value={value}/>)
            }
        ]
    )

    useEffect(() =>{
        if(pageLoad === 0){
            dispatch(setInitial());
            loadProperties();
            pageLoad++;
        }
    }, [user]);

    const loadProperties = () => {
        setIsLoaded(false);
        setApi([]);
        user.properties.map((property, index) => {
            loadData(property)
        });
    }

    const loadData = async (property) => {
        all_reports(property.id, dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'))
            .then(response => {
                setDateRanges(() => {
                    let data = [];
                    if(response.data.ads !== null){
                        for (const row of response.data.ads) {
                            data.push(row.range);
                        }
                    }else if(response.data.meta !== null){
                        for (const row of response.data.meta) {
                            data.push(row.range);
                        }
                    }
                    return data;
                });

                setApi((api) => [...api, {
                    id: property.client_id,
                    hotelCode: property.meta_hotel_code,
                    name: property.name,
                    analytics: response.data.ga && response.data.ga !== null ? macroAnalytics(response.data.ga.data) : null,
                    ads: response.data.ads && response.data.ads !== null ? macroAds(response.data.ads) : null,
                    meta: response.data.meta && response.data.meta !== null ? macroMeta(response.data.meta, response.data.meta_cost) : null,
                    isMeta: property.meta_hotel_code !== null && property.meta !== false ? true : false,
                    isAds: response.data.ads !== null && property.ads !== false ? true : false,
                    isAnalytics: response.data.ga !== null && property.analytics !== false ? true : false,
                }]);
            })
            .catch(err => {
                console.log(err);
            });
        setIsLoaded(true);
    }

    useEffect(() => {
        setFiltersList([])
        if(isLoaded){
            if (filters.length === 0) {
                setFiltersList(api);
            } else {
                let arrTab = [...api];
                filters.forEach(filter => {
                    arrTab = arrTab.filter(property => {
                        return property[`is${filter.charAt(0).toUpperCase() + filter.slice(1)}`] === true;
                    });
                });
                setFiltersList(arrTab);
            }
        }
    }, [filters]);

    return (
        <article className="c-section-content">
                <div className="header-title macro">
                    <h2>Macro</h2>
                    <div className="macro-selector">
                        <CustomMultiSelect/>
                        <SelectBar loadData={loadProperties} dateRanges={dateRanges} />
                    </div>
                </div>
            {
                isLoaded  && api.length !== 0 ? (

                    <CustomList entity={filtersList.length === 0 ? api : filtersList} columns={columns.filter(column => !column.hide)} activeFilters={columns.filter(column => !column.hide)} className="macro_list" isFetchable={false} isSelectable={false} isPaginable={true} isMacro={true}/>

                ) : (
                    <div className="loader-container"><CircularProgress size={30} /></div>
                )
            }

        </article>
    )
}

export default MacroView;