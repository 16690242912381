import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectFilters } from "../../../reducers/filterSlice";
import { selectUser } from "../../../reducers/userSlice";
import CustomMultiSelect from "../../custom/CustomMultiSelect";
import CustomTag from "../../custom/CustomTag";
import CustomList from "../../custom/CustomList";
import { Link } from "react-router-dom";
import "./PropertyList.scss";

const PropertyList = () => {
  const user = useSelector(selectUser);
  const filters = useSelector(selectFilters);
  const [properties, setProperties] = React.useState(user?.properties || []);

  useEffect(() => {
    let updatedProperties = [...(user?.properties || [])];

    if (filters.length > 0) {
      filters.forEach((filter) => {
        updatedProperties = updatedProperties.filter(property => property[filter] === true);
      });
    }

    updatedProperties.sort((a, b) => a.id - b.id);

    setProperties(updatedProperties);
  }, [filters, user?.properties]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        icon: 'id',
      },
      {
        Header: 'Nom',
        accessor: 'name',
        icon: 'nom',
      },
      {
        Header: 'Type GA',
        accessor: 'ga_type',
        icon: 'role',
        Cell: ({ cell: { value } }) => <CustomTag value={value} />,
      },
    ],
    []
  );

  return (
    <div className="c-section-content">
      <div className="list-header">
        <div className="header-title properties">
          <h2>Vos propriétés</h2>
        </div>
        {user.role === "admin" ? <CustomMultiSelect /> : null}
      </div>
      {user.role === "admin" ? (
        <div className="transactions-container properties">
          <CustomList
            columns={columns}
            entity={properties}
            enableLink
            isFetchable={false}
            isSelectable={false}
          />
        </div>
      ) : (
        <div className="cards-container">
          {properties.map((property) => (
            <div className="property-card" key={property.id}>
              <div className="property-card-header">
                <p>{property.name}</p>
                <p>#{property.id}</p>
              </div>
              <div className="card-ga_type">
                <p>Type GA</p>
                <CustomTag value={property.ga_type} />
                <Link
                  to={`${property.id}`}
                  key={property.id}
                  className="property-card-link"
                >
                  <svg
                    width="22"
                    height="21"
                    viewBox="0 0 22 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.43478 11.0348H14.4783C14.7735 11.0348 15.013 10.7953 15.013 10.5C15.013 10.2048 14.7735 9.96525 14.4783 9.96525H1.43478C1.13955 9.96525 0.9 10.2048 0.9 10.5C0.9 10.7953 1.13955 11.0348 1.43478 11.0348Z"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="0.2"
                    />
                    <path
                      d="M10.6214 14.3568L10.6218 14.3572C10.7263 14.4607 10.8631 14.5131 10.9999 14.5131C11.1368 14.5131 11.2736 14.4607 11.3781 14.3572L11.3785 14.3568L14.8567 10.8786C15.0654 10.67 15.0654 10.331 14.8567 10.1224L11.3785 6.64411C11.1699 6.43549 10.8309 6.43549 10.6223 6.64411C10.4137 6.85273 10.4137 7.1917 10.6223 7.40031L13.722 10.5L10.6214 13.6006C10.4128 13.8092 10.4128 14.1482 10.6214 14.3568Z"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="0.2"
                    />
                    <path
                      d="M2.32122 13.722L2.32124 13.722C3.68233 17.5759 7.34449 20.1652 11.4347 20.1652C16.7638 20.1652 21.0999 15.8292 21.0999 10.5C21.0999 5.17089 16.7638 0.834814 11.4347 0.834814C7.34449 0.834814 3.68231 3.42412 2.3221 7.27893L2.32209 7.27896C2.2237 7.55808 2.37028 7.8629 2.64826 7.96126L2.64891 7.96149C2.92617 8.05746 3.23207 7.91379 3.33068 7.63513L3.33071 7.63504C4.54006 4.20725 7.79701 1.90438 11.4347 1.90438C16.1742 1.90438 20.0303 5.76048 20.0303 10.5C20.0303 15.2396 16.1742 19.0957 11.4347 19.0957C7.79701 19.0957 4.54005 16.7928 3.32983 13.3659L3.32981 13.3658C3.23121 13.0872 2.92623 12.9435 2.64815 13.0394L2.64739 13.0397C2.36941 13.138 2.22283 13.4428 2.32122 13.722Z"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="0.2"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PropertyList;
