import React, {useState} from "react";
import Select from "react-select";
import CustomOption from "./CustomOption";

import { useSelector, useDispatch } from 'react-redux'
import { changePeriod, selectPeriod } from '../../reducers/filterSlice';

const CustomSelectInput = ({dateRanges, hideFlexButton}) => {

    const dispatch = useDispatch();
    const period = useSelector(selectPeriod);
    const [items, setItems] = useState([]);

    React.useEffect(() => {
        setItems([
            { value: 'data_1', label: 'Période -1', date_range: dateRanges[1] },
            { value: 'data_2', label: 'Période -2', date_range: dateRanges[2] },
            { value: 'data_3', label: 'Année n-1', date_range: dateRanges[3] },
            { value: 'data_0', label: 'Réinitialiser', date_range: dateRanges[0]},
        ])
    }, [dateRanges]);

    const handleComparaison = () => {
        dispatch(changePeriod("data_3"));
    }

    return period !== "data_0" ? (
        <Select
            name="colors"
            isRequired={true}
            options={items}
            onChange={(newValue) => dispatch(changePeriod(newValue.value))}
            value={items.find(item => item.value === period)}
            className="basic-single single-search-input no-padding period-select"
            classNamePrefix="select"
            placeholder="Période"
            components={{ Option: CustomOption }}
        />
    ):(
        !hideFlexButton && (
            <div className='flex-button-right'>
                <button type="button" className="main-button without-icon" onClick={handleComparaison}>Comparer</button>
            </div>
        )
    )
}

export default CustomSelectInput;
