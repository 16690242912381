import React from "react";
import {Routes, Route} from "react-router-dom";
import CustomerList from "./CustomerList";
import CustomerPanel from "../../customers/CustomerPanel";

const Customers = () => {
    return (
        <Routes>
            <Route path=":propertyId" element={<CustomerPanel adminMode />} />
            <Route path="*" element={<CustomerList />} />
        </Routes>
    );
}

export default Customers;
