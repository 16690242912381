import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeStartDate,
  changeEndDate,
  selectEndDate,
  selectStartDate,
} from '../../reducers/filterSlice.jsx';
import dayjs from 'dayjs';
import { IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const CustomDateInput = ({ loadData }) => {
  const dispatch = useDispatch();
  const globalStartDate = useSelector(selectStartDate);
  const globalEndDate = useSelector(selectEndDate);

  const [tempStartDate, setTempStartDate] = useState(globalStartDate);
  const [tempEndDate, setTempEndDate] = useState(globalEndDate);
  const [toSave, setToSave] = useState(false);

  useEffect(() => {
    setTempStartDate(globalStartDate);
    setTempEndDate(globalEndDate);
  }, [globalStartDate, globalEndDate]);

  const handleSave = () => {
    dispatch(changeStartDate(dayjs(tempStartDate).format('YYYY-MM-DD')));
    dispatch(changeEndDate(dayjs(tempEndDate).format('YYYY-MM-DD')));
    setToSave(false);
    loadData();
  };

  return (
    <div className="input-datepicker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Début"
          value={tempStartDate}
          maxDate={dayjs(new Date()).subtract(1, 'day')}
          onChange={(newValue) => {
            setTempStartDate(newValue);
            setToSave(true);
          }}
          className="start-date"
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          label="Fin"
          value={tempEndDate}
          minDate={tempStartDate || dayjs(new Date()).subtract(1, 'day')}
          maxDate={dayjs(new Date())}
          onChange={(newValue) => {
            setTempEndDate(newValue);
            setToSave(true);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <IconButton
          aria-label="Sauvegarder"
          color="success"
          className={toSave ? 'custom-tag active visible' : 'save-dates'}
          onClick={handleSave}
        >
          <CheckIcon />
        </IconButton>
      </LocalizationProvider>
    </div>
  );
};

export default CustomDateInput;