import CustomDateInput from "../custom/CustomDateInput";
import CustomSelectInput from "../custom/CustomSelectInput";
import React from "react";
import { useSelector } from "react-redux";
import { selectPeriod } from "../../reducers/filterSlice";

const SelectBar = ({ loadData, dateRanges, hideFlexButton, tabLivieAffichage }) => {
    const period = useSelector(selectPeriod);

    return (
        <div className="searchbar-container">
            <div className="selectbar-item">
                <CustomDateInput loadData={loadData} />
                {!tabLivieAffichage && period !== "data_0" ? <span className="compared-to">comparé à </span> : null}
                {!tabLivieAffichage && <CustomSelectInput dateRanges={dateRanges} hideFlexButton={hideFlexButton} />}
            </div>
        </div>
    );
}

export default SelectBar;