import React from "react";
import CustomTooltip from './CustomTooltip';

function removePourcent(text) {
    const newVal = text.replace("%", "");
    return parseInt(newVal)/100;
}

function getPourcent(data, period){

    let pourcent;

    if(data[period] !== null && data["data_0"] !== null){
        if(!data.data_0.toString().includes("%")){
            pourcent = Math.round(((data.data_0 - data[period]) / data[period]) * 100)
        }else{
            pourcent = Math.round(((removePourcent(data.data_0) - removePourcent(data[period])) / removePourcent(data[period])) * 100)
        }
    }else{
        pourcent = Infinity;
    }

    return pourcent;
}

const formatNumber = (value, decimal = 0, attr = null) => {
    const nb = Number(value);
    if (isNaN(nb)) {
        return "N/A"
    }
    return nb.toLocaleString('fr-FR', {maximumFractionDigits: decimal}) + (attr === null ? "" : ` ${attr}`)
}

const InlineInfos = ({data, period, isForDoughnut, isInvert, decimals = 0, isCompare }) => {

    return (
        <div className={"card_inline " + isForDoughnut}>
            <div className="c-column">
                <span className="c-card_title">{data.label}</span>
                { period !== "data_0" ? <span className="refer">Contre <strong>{ formatNumber(data[period], 0, data.attribute || null) }</strong> sur la période précédente</span> : null}
            </div>
            <div className="row_inline">
                <p className="c-card_data">{formatNumber(data.data_0, data.decimals || decimals, data.attribut || null)}</p>
                { period !== "data_0" ? <span className={`pourcent ${getPourcent(data, period) === Infinity ? "neutral" : ""} ${ isInvert ? `${getPourcent(data, period) > 0 ? "negative" : "positive"}` : `${getPourcent(data, period) > 0 ? "positive" : "negative"}` }`} >{getPourcent(data, period) === Infinity ? "–––" : <>{getPourcent(data, period) > 0 ? "+" : null}{getPourcent(data, period)}%</>}</span> : null}
            </div>
        </div>
    )
}

const CustomBigCard = ({data_first, data_second, data_third, data_fourth, data_fifth, period, tooltip, isInvert = false, isForDoughnut = false, isCompare = false}) => {

    return (
        <div className="c-card">
            <div className="c-card_top">
                <div className="c-row_top">
                    <p className="c-card_data">{Number(data_first.data_0).toLocaleString('fr-FR', {maximumFractionDigits: 0})} {data_first.attribut ?  data_first.attribut : null}</p>
                    { period !== "data_0" ? <span className={`pourcent ${getPourcent(data_first, period) === Infinity ? "neutral" : ""} ${ isInvert ? `${getPourcent(data_first, period) > 0 ? "negative" : "positive"}` : `${getPourcent(data_first, period) > 0 ? "positive" : "negative"}` }`} >{getPourcent(data_first, period) === Infinity ? "–––" : <>{getPourcent(data_first, period) > 0 ? "+" : null}{getPourcent(data_first, period)}%</>}</span> : null}
                </div>
                {tooltip !== false ? <CustomTooltip text={tooltip}/> : null }
            </div>
            <span className="c-card_title">{data_first.label}</span>
            { period !== "data_0" ? <span className="refer">Contre <strong>{ Number(data_first[period]).toLocaleString('fr-FR', {maximumFractionDigits: 0}) } {data_first.attribut ? data_first.attribut : null}</strong> sur la période précédente</span> : null}
            <div className="divider" />
            <div style={{width: "100%"}}>
                <InlineInfos data={data_second} period={period} isForDoughnut={isForDoughnut ? 'donuts blue' : null} isInvert={isInvert} isCompare={isCompare}/>
                <InlineInfos data={data_third} period={period} isForDoughnut={isForDoughnut ? 'donuts yellow' : null} isCompare={isCompare}/>
                { data_fourth ? <InlineInfos data={data_fourth} period={period} isForDoughnut={isForDoughnut ? 'donuts pink' : null} isCompare={isCompare}/>  : null }
                { data_fifth ? <InlineInfos data={data_fifth} period={period} isForDoughnut={isForDoughnut ? 'donuts green' : null} isCompare={isCompare}/>  : null }
            </div>
        </div>
    )
}

export default CustomBigCard;
