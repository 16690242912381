import { createSlice } from '@reduxjs/toolkit';
import dayjs from "dayjs";

const initialState = {
   period: "data_0",
   endDate: dayjs().date(dayjs(new Date()).daysInMonth() === 31 ? 31 : 30).subtract(1, "month").format(),
   startDate: dayjs().date(1).subtract(1, "month").format(),
   filters: [],
   search: null
}

export const filterSlice = createSlice({
   name: "filters",
   initialState: initialState,
   reducers: {
      changePeriod: (state, action) => {
         state.period = action.payload
      },
      changeStartDate: (state, action) => {
         state.startDate = dayjs(action.payload).format('YYYY-MM-DD')
      },
      changeEndDate: (state, action) => {
         state.endDate = dayjs(action.payload).format('YYYY-MM-DD')
      },
      setFilters: (state, action) => {
         state.filters = action.payload
      },
      setSearch: (state, action) => {
         state.search = action.payload
      },
      setInitial: (state) => {
         state.endDate = initialState.endDate;
         state.startDate = initialState.startDate
         state.period = initialState.period
         state.filters = initialState.filters
      }
   }
})

export const { changePeriod, changeStartDate, changeEndDate, setSearch, eraseSearch, setInitial, setFilters } = filterSlice.actions

export const selectPeriod = (state) => state.filters.period;
export const selectStartDate = (state) => state.filters.startDate;
export const selectEndDate = (state) => state.filters.endDate;
export const selectSearch = (state) => state.filters.search;
export const selectFilters = (state) => state.filters.filters;

export default filterSlice.reducer
