import React, { useState } from 'react';
import LogoAltelis from "../../assets/icons/logo-altelis-v2.svg";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import "./Login.scss";

const PasswordForgot = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSubmit = async () => {
    setLoader(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setMessage("Un e-mail de réinitialisation du mot de passe a été envoyé.");
    } catch (error) {
      setError("Une erreur s'est produite. Veuillez réessayer plus tard.");
    } finally {
      setLoader(false);
    }
  }

  return (
    <div className="main-login">
      <div className="login-background" />
      <div className="login-container">
        <div className="login-form">
          <div className='img-container'>
            <img src={LogoAltelis} alt="Logo Altelis Monitor"/>
          </div>
          <div className='login-form-right'>
            <h1>Mot de passe oublié</h1>
            <p className='welcome-message'>Renseignez votre adresse email</p>
            <div className='custom-hr' />
            {error ? <p className="login-error">{error}</p> : null}
            {message ? <p className="login-message">{message}</p> : null}
            <div className='login-input-group'>
              <span>Email <span className='required'> *</span></span>
              <input type="email" placeholder='Saisissez votre adresse email' value={email} onChange={(e) => {
                setEmail(e.target.value)
                setError('')
              }} />
            </div>
            <div className='login-button-container'>
              {loader ? (
                <button className="btn btn-primary login-button-loading" disabled><CircularProgress size={20} className="white-loader" /></button>
              ) : (
                <button className="btn btn-primary login-button" type="button" onClick={handleSubmit}>Envoyer</button>
              )}
            </div>
            <Link to='/login' className='forgot-password'>Retour à la connexion</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswordForgot;